// AT-2018 | AT-2260 | Ankur Makavana | 04/08/2023 | create new screen for transformation
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { MappingEditor } from "./TransformationMappingEditor";
import {PEDiv,PEButton} from './permissionComponents/index'
import { Switch } from 'antd';


function Transformation_mapping(props) {
  const [loading, sendRequest] = useAxios();
  const [VendorEvent_list, setVendorEvent_list] = useState();
  const [ndid, setndid] = useState();
  const [ndfield_list, setndfield_list] = useState([]);
  const [vendorfield_list, setvendorfield_list] = useState([]);
  const [VendorEvent, setVendorEvent] = useState();
  const [ndevent_name, setndevent_name] = useState();
  const [vendor_id, setvendor_id] = useState();
  const [ndEvent_list, setndEvent_list] = useState([]);
  const [vendor_list, setvendor_list] = useState();
  const [mapping_data, setmapping_data] = useState();
  const [custom_target_topic, setcustom_target_topic] = useState("");
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [view_id, setview_id] = useState();
  const [isDisabled, setisDisabled] = useState(false);
  let [DireError, setDirecError] = useState(false);
  let [eventD, setEventDir] = useState("I");
  let [isActive, setisActive] = useState();
  let [aggregate_flag, setaggregate_flag] = useState();
  let [group_id_key_name, setgroup_id_key_name] = useState();
  let [group_id_key_field_name, setgroup_id_key_field_name] = useState();
  const [pageNumber, setpageNumber] = useState(1);
  const [attributes,setattributes] = useState([{
    "attribute_key":"",
    "attribute_value":"",
    "attribute_type":"static"
  }]);
  const [isloading, setLoading] = useState(false);
  let [storeOnSource, setStoreOnSource] = useState([]);
  let [query, setquery] = useState();
  let [query_data_keys, setquery_data_keys] = useState();
  const [fieldlist, setfieldlist] = useState([
    {
      NDFields: "",
      VendorFields: "",
      ven_defaultval: "",
      isTransform: "",
      query_key: "",
    },
  ]);

  const [fieldlist_all, setfieldlist_all] = useState([]);
  const [fieldlist_show, setfieldlist_show] = useState([]);

  const [conditionfieldlist, setconditionfieldlist] = useState([]);
  const [transformation_name, settransformation_name] = useState();

  const [switchStates, setSwitchStates] = useState([false]);

 

  useEffect(() => {
    const { view_id } = (props.location && props.location.state) || {};
    let apitoken = localStorage.getItem("api_token");
    if (view_id) {
      setview_id(view_id);
      sendRequest(
        {
          url:
            `${process.env.REACT_APP_BASE_URL}/get/mapping/data?id=` + view_id,
          method: "GET",
          //data: inputdata,
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log(
            "response[0].event_direction",
            response[0].event_direction
          );
          setmapping_data(response);
          setndevent_name(response[0].nd_event_id);
          settransformation_name(response[0].transformation_name);
          setvendor_id(response[0].vendor_id);
          setVendorEvent(response[0].vendor_event_id);
          setfieldlist(response[0].event_config);
          setfieldlist_all(response[0].event_config);
          if (
            response[0].store_on_source != null &&
            response[0].store_on_source != ""
          ) {
            setStoreOnSource(response[0].store_on_source);
          }
          setquery(response[0].query);
          setquery_data_keys(response[0].query_data_keys);

          setEventDir(response[0].event_direction);
          setisActive(response[0].is_active);
          response[0].aggregate_flag &&
            setaggregate_flag(response[0].aggregate_flag);

          if (response[0].group_id_key_name) {
            setgroup_id_key_name(response[0].group_id_key_name);
          }
          if (response[0].group_id_key_field_name) {
            setgroup_id_key_field_name(response[0].group_id_key_field_name);
          }

          if (response[0].conditions != null && response[0].conditions != "") {
            setconditionfieldlist(response[0].conditions);
          }

          if(response[0].attributes && response[0].attributes.length > 0){
            {/*let _attributes = Object.keys(response[0].attributes).reduce((obj,key)=>{
              obj.push({
                'attribute_key':key,
                'attribute_value':response[0].attributes[key],
                'attribute_type': "static"
              })
              return obj
            },[])
            setattributes(_attributes) */}
            setattributes(response[0].attributes); 

            setSwitchStates(response[0].attributes.map(obj=> obj.attribute_type=="dynamic"? true: false || false))

          }
          //setjiraTicket(response);
        }
      );
    }
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/nd/id`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndid(response[0].vendor_id);
        //setjiraTicket(response);
      }
    );

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setvendor_list(response);
        //setjiraTicket(response);
      }
    );
  }, []);
  useEffect(() => {
    if (ndid) {
      get_ndfields();
    }
  }, [ndid]);
  useEffect(() => {
    if (vendor_id) {
      getvendor_event();
    }
  }, [vendor_id]);
  useEffect(() => {
    if (ndevent_name && ndid) {
      console.log("b->", ndid + "-" + ndevent_name);
      get_ndevent_fields();
    }
  }, [ndevent_name, ndid]);
  useEffect(() => {
    if (VendorEvent && vendor_id) {
      console.log("a->", VendorEvent + "-" + vendor_id);
      getvendor_field();
    }
  }, [VendorEvent, vendor_id]);
  useEffect(() => {
    if (fieldlist_all.length != 1) {
      loadMoreData();
    }
  }, [fieldlist_all]);
  useEffect(() => {
    const handleScroll = () => {
      const distanceFromBottom =
        document.documentElement.scrollHeight -
        (window.innerHeight + window.scrollY);
      // Define a threshold (in pixels) for triggering the loadMore function
      const loadThreshold = 100;
      // Check if the user has scrolled to the bottom of the page
      // if (distanceFromBottom < loadThreshold && !isloading) {
      //   // Load more data when the user reaches the bottom
      //   loadMoreData();
      // }
    };
    // Add the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pageNumber]);
  const loadMoreData = () => {
    setLoading(true);
    let chunkSize = 5;
    const startIdx = (pageNumber - 1) * chunkSize;
    const endIdx = startIdx + chunkSize;
    const newDataChunk = fieldlist_all.slice(startIdx, endIdx);
    setfieldlist_show((prevData) => {
      // Return a new array combining the previous data and the new data
      return [...prevData, ...newDataChunk];
    });
    setpageNumber((prevPageNo) => prevPageNo + 1);
    setLoading(false);
  };

  const handleSwitchChange = (index,field, checked) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = checked;
    handleInputChangeAttribute(index, field, checked?"dynamic":"static")
    setSwitchStates(updatedSwitchStates);
  };

  const handleAddItem = () => {
    const newStoreOnSource = [
      ...storeOnSource,
      {
        query: "",
        query_data_keys: "",
        source_path: "",
        payload_path: "",
        type: "",
      },
    ];
    setStoreOnSource(newStoreOnSource);
  };

  const handleRemoveItem = (index) => {
    const newStoreOnSource = [...storeOnSource];
    newStoreOnSource.splice(index, 1);
    setStoreOnSource(newStoreOnSource);
  };

  const handleInputChangestore = (index, field, value) => {
    const newStoreOnSource = [...storeOnSource];
    newStoreOnSource[index][field] = value;
    setStoreOnSource(newStoreOnSource);
  };
  const handleAddItemAttribute = () => {
    const newAttribute = [
      ...attributes,
      {
        "attribute_key":"",
        "attribute_value":"",
        "attribute_type": "static"
      },
    ];
    setattributes(newAttribute);
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates.push(false);
    setSwitchStates(updatedSwitchStates);

  };

  const handleRemoveItemAttribute = (index) => {
    const newAttribute = [...attributes];
    newAttribute.splice(index, 1);
    setattributes(newAttribute);
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates.splice(index, 1);
    setSwitchStates(updatedSwitchStates);
  };

  const handleInputChangeAttribute = (index, field, value) => {
    const newAttribute = [...attributes];
    newAttribute[index][field] = value;
    setattributes(newAttribute);
  };
  const get_ndfields = async () => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/events?vendor_id=` +
          ndid,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndEvent_list(response);
        //setjiraTicket(response);
      }
    );
  };  
  const handleInputChange = (e, index, api_index = null) => {
    const { name, value } = e.target;
    if (api_index === null) {
      const list = [...fieldlist];
      list[index][name] = value;
      setfieldlist(list);
      const list_show = [...fieldlist_show];
      list_show[index][name] = value;
      setfieldlist_show(list_show);
      console.log("list_show", list_show);
      if (name == "query_key") {
        const filteredData = list_show.filter((item) => item.query_key === "1");
        if (eventD == "I") {
          const filteredNDFields = filteredData
            .map((item) => item.NDFields)
            .filter((ndField) => ndField !== "")
            .join(", ");
          console.log("filteredNDFields", filteredNDFields);
          setquery_data_keys(filteredNDFields);
        }
        if (eventD == "O") {
          const filteredVendorFields = filteredData
            .map((item) => item.VendorFields)
            .filter((vendorField) => vendorField !== "")
            .join(", ");
          console.log("filteredVendorFields", filteredVendorFields);
          setquery_data_keys(filteredVendorFields);
        }
      }
    }
  };

  const getvendor_event = (vendorid) => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/events?vendor_id=` +
          (vendorid ? vendorid : vendor_id),
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setVendorEvent_list(response);
        //setjiraTicket(response);
      }
    );
  };
  const get_ndevent_fields = (ndevent) => {
    let apitoken = localStorage.getItem("api_token");
    console.log(
      "get_ndevent_fields->",
      ndid + "-" + ndevent ? ndevent : ndevent_name
    );
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/mapping/data?vendor_id=${ndid}&vendor_event_id=` +
          (ndevent ? ndevent : ndevent_name),
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setndfield_list(response[0].payload_field);
        //setjiraTicket(response);
      }
    );
  };
  //AT-2326||Rohit Chitampalle||28-08-2023||Set Event Direction
  const set_EventDirection = (e) => {
    setEventDir(e.target.value);
  };
  const getvendor_field = (vendorenv) => {
    let apitoken = localStorage.getItem("api_token");
    console.log(
      "getvendor_field->",
      vendor_id + "-" + vendorenv ? vendorenv : VendorEvent
    );
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/vendor/mapping/data?vendor_id=${vendor_id}&vendor_event_id=` +
          (vendorenv ? vendorenv : VendorEvent),
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        setvendorfield_list(response[0].payload_field);
        //setjiraTicket(response);
      }
    );
  };
  const isEmptyObject = (obj) => {
    return Object.keys(obj).every((key) => obj[key] === "");
  };
  const save_mapping = () => {
    setisDisabled(true);
    const form = new FormData();
    form.append("vendor_event_id", VendorEvent);
    // temporary solution for __default__ key ambiguity
    let _fieldlist = fieldlist.reduce((obj, item) => {
      if (item["VendorFields"] == "__default__") {
        obj.push({ ...item, VendorFields: "" });
      } else if (item["NDFields"] == "__default__") {
        obj.push({ ...item, NDFields: "" });
      } else {
        obj.push({ ...item });
      }
      return obj;
    }, []);
    form.append("event_config", JSON.stringify(_fieldlist));
    form.append("nd_event_id", ndevent_name);
    //Added event_direction
    form.append("event_direction", eventD);
    form.append("is_active", isActive);
    form.append("custom_target_topic", custom_target_topic);
    if (
      conditionfieldlist.length > 0 &&
      conditionfieldlist[0].field_name != "" &&
      conditionfieldlist[0].field_value != ""
    ) {
      form.append("conditions", JSON.stringify(conditionfieldlist));
    } else {
      form.append("conditions", "");
    }
    if (aggregate_flag) {
      form.append("aggregate_flag", aggregate_flag);
    }
    if (group_id_key_name) {
      form.append("group_id_key_name", group_id_key_name);
    }
    if (group_id_key_field_name) {
      form.append("group_id_key_field_name", group_id_key_field_name);
    }
    if (view_id) {
      form.append("mapping_id", view_id);
    }
    const isEmpty = storeOnSource.every(isEmptyObject);
    if (!isEmpty) {
      form.append("store_on_source", JSON.stringify(storeOnSource));
    }
    if (query) {
      form.append("query", query);
    }
    if (query_data_keys) {
      form.append("query_data_keys", query_data_keys);
    }
    let _attributes = attributes.reduce((obj,item ,index)=>{
      if(item.attribute_key !=""){
        obj.push({
          "attribute_key": item.attribute_key,
          "attribute_value": item.attribute_value,
          "attribute_type": item.attribute_type ? item.attribute_type : switchStates[index]
      });
      } 
      return obj
    },[])
    form.append("attributes",JSON.stringify(_attributes));
  
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/set/vendor/nd/event_mapping`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        //setjiraTicket(response);
        setisErrOpen(true);
        setapi_error_message(response[0].message);
      }
    );
  };
  const handleAddChildMapping = (index) => {
    const newData = [...fieldlist];
    newData[index].child_mappings = newData[index].child_mappings || [];
    newData[index].child_mappings.push({ key: "", value: "" });
    console.log("newData", newData);
    setfieldlist(newData);

    const newDataview = [...fieldlist_show];
    newDataview[index].child_mappings = newDataview[index].child_mappings || [];
    newDataview[index].child_mappings.push({ key: "", value: "" });
    setfieldlist_show(newDataview);
  };

  const handleRemoveChildMapping = (parentIndex, childIndex) => {
    const newData = [...fieldlist];
    newData[parentIndex].child_mappings.splice(childIndex, 1);
    setfieldlist(newData);

    const newDataview = [...fieldlist_show];
    newDataview[parentIndex].child_mappings.splice(childIndex, 1);
    setfieldlist_show(newDataview);
  };

  const handleTextboxChange = (parentIndex, childIndex, textbox, value) => {
    const newData = [...fieldlist];
    newData[parentIndex].child_mappings[childIndex][textbox] = value;
    setfieldlist(newData);

    const newDataview = [...fieldlist_show];
    newDataview[parentIndex].child_mappings[childIndex][textbox] = value;
    setfieldlist_show(newDataview);
  };
  console.log("fieldlist_show", eventD);
  return (
    <div className="container">
      <Commonheader pagetitle={"Create Mapping"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="viewname-holder">
          <div className="row">
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Name</label>
              <input
                className="form-control"
                name="transformation_name"
                value={transformation_name}
                onChange={(e) => settransformation_name(e.target.value)}
              />
            </div>
            <PEDiv element_id="transformationmapp_ndevent" className="form-group col-md-3">
              <label htmlFor="inputEmail4">ND Event</label>
              <select
                className="form-control"
                name="ndevent_name"
                value={ndevent_name}
                disabled={view_id}
                onChange={(e) => {
                  {
                    setndevent_name(e.target.value);
                    get_ndevent_fields(e.target.value);
                  }
                }}
              >
                <option>Select Event</option>
                {ndEvent_list &&
                  ndEvent_list.map((ndeve) => (
                    <option value={ndeve.vendor_event_id}>
                      {ndeve.event_name}
                    </option>
                  ))}
              </select>
            </PEDiv>
            <PEDiv element_id="transformationmapp_vendor" className="form-group col-md-3">
              <label htmlFor="inputEmail4">Vendor</label>
              <select
                className="form-control"
                name="vendor_id"
                value={vendor_id}
                disabled={view_id}
                onChange={(e) => {
                  setvendor_id(e.target.value);
                  getvendor_event(e.target.value);
                }}
              >
                <option>Select Vendor</option>
                {vendor_list &&
                  vendor_list.map((vendordata) => (
                    <option value={vendordata.vendor_id}>
                      {vendordata.vendor_name}
                    </option>
                  ))}
              </select>
            </PEDiv>
            <PEDiv element_id="transformationmapp_vendorevent" className="form-group col-md-3">
              <label htmlFor="inputEmail4">Vendor Event</label>
              <select
                className="form-control"
                name="VendorEvent"
                value={VendorEvent}
                disabled={view_id}
                onChange={(e) => {
                  {
                    setVendorEvent(e.target.value);
                    getvendor_field(e.target.value);
                  }
                }}
              >
                <option>Select Vendor Event</option>
                {VendorEvent_list &&
                  VendorEvent_list.map((vendorEnv) => (
                    <option value={vendorEnv.vendor_event_id}>
                      {vendorEnv.event_name}
                    </option>
                  ))}
              </select>
            </PEDiv>
            <PEDiv element_id="transformationmapp_isactive" className="form-group col-md-3">
              <label htmlFor="inputEmail4">Is Active</label>
              <select
                className="form-control"
                name="event_direction"
                value={isActive}
                onChange={(e) => {
                  setisActive(e.target.value);
                }}
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </PEDiv>
            <PEDiv element_id="transformationmapp_direvtion" className="form-group col-md-3">
              <label htmlFor="inputEmail4">Event Direction</label>
              <select
                className="form-control"
                name="event_direction"
                value={eventD}
                disabled={view_id}
                onChange={(e) => {
                  set_EventDirection(e);
                }}
              >
                <option value="I">In-Coming</option>

                <option value="O">Out-Going</option>
              </select>
            </PEDiv>
            <PEDiv element_id="transformationmapp_custom_topic" className="form-group col-md-3">
                    <label>Custom Topic</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="custom target topic"
                      value={custom_target_topic}
                      onChange={(e) =>
                        setcustom_target_topic(e.target.value)
                      }
                    />
              </PEDiv>
            {/* <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Query</label>
              <input
                className="form-control"
                name="query"
                value={query}
                onChange={(e) => setquery(e.target.value)}
              />
            </div> */}
          </div>
          <div>
            {/*{console.log("fieldlist", fieldlist)}
            {console.log("ndfield_list", ndfield_list)}
            {console.log("vendorfield_list", vendorfield_list)}
            {console.log("conditionfieldlist", conditionfieldlist)}
            {console.log("Eventdirection", eventD)} */}
            <MappingEditor
              payloadMappings={fieldlist}
              event_direction={eventD}
              ndfield_list={ndfield_list}
              vendorfield_list={vendorfield_list}
              conditions={conditionfieldlist}
              onChange={({ conditions, payloadMappings }) => {
                console.log(conditions, payloadMappings);
                setconditionfieldlist(conditions);
                setfieldlist(payloadMappings);
              }}
            />
          </div>
          <div className="row mt-4">
            <strong className="mb-2">store on source</strong>
            {storeOnSource &&
              storeOnSource.map((item, index) => (
                <div class="form-group col-md-12 row block-shadow">
                  <PEDiv element_id="transformationmapp_query" class="form-group col-md-3">
                    <label>Query</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Query"
                      value={item.query}
                      onChange={(e) =>
                        handleInputChangestore(index, "query", e.target.value)
                      }
                    />
                  </PEDiv>
                  <PEDiv element_id="transformationmapp_querydata" class="form-group col-md-3">
                    <label>Query Data Keys</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Query Data Keys"
                      value={item.query_data_keys}
                      onChange={(e) =>
                        handleInputChangestore(
                          index,
                          "query_data_keys",
                          e.target.value
                        )
                      }
                    />
                  </PEDiv>
                  <PEDiv element_id="transformationmapp_sourcepath" class="form-group col-md-3">
                    <label>Source Path</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Source Path"
                      value={item.source_path}
                      onChange={(e) =>
                        handleInputChangestore(
                          index,
                          "source_path",
                          e.target.value
                        )
                      }
                    />
                  </PEDiv>
                  <PEDiv element_id="transformationmapp_payloadpath"class="form-group col-md-2">
                    <label>Payload Path</label>
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Payload Path"
                      value={item.payload_path}
                      onChange={(e) =>
                        handleInputChangestore(
                          index,
                          "payload_path",
                          e.target.value
                        )
                      }
                    /> */}
                    <select
                      className="form-control"
                      name="field_name"
                      value={item.payload_path}
                      onChange={(e) =>
                        handleInputChangestore(
                          index,
                          "payload_path",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Field</option>
                      <option value="*">*</option>
                      {eventD && eventD == "O"
                        ? vendorfield_list &&
                          vendorfield_list.map((vendorfield) => (
                            <option value={vendorfield.payload_field}>
                              {vendorfield.payload_field}
                            </option>
                          ))
                        : eventD == "I"
                        ? ndfield_list &&
                          ndfield_list.map((ndfield) => (
                            <option value={ndfield.payload_field}>
                              {ndfield.payload_field}
                            </option>
                          ))
                        : ""}
                    </select>
                  </PEDiv>
                  <div class="form-group col-md-2">
                    <label>Automation</label>

                    <select
                      className="form-control"
                      name="automation_flag"
                      value={item.automation_flag}
                      onChange={(e) =>
                        handleInputChangestore(
                          index,
                          "automation_flag",
                          e.target.value === "true"
                        )
                      }
                    >
                      <option value="">Select Field</option>
                      {[
                        { option: "Yes", value: true },
                        { option: "No", value: false },
                      ].map((item) => {
                        return (
                          <option value={item.value}>{item.option}</option>
                        );
                      })}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Type"
                      value={item.type}
                      onChange={(e) =>
                        handleInputChangestore(index, "type", e.target.value)
                      }
                    />
                  </div> */}
                  <div className="form-group col-md-1">
                    <button
                      className="btn btn-danger form-group mr-2"
                      onClick={() => handleRemoveItem(index)}
                    >
                      -
                    </button>
                  </div>
                </div>
              ))}
            <div className="row w-100 mt-4 d-flex justify-content-center align-items-center">
              {storeOnSource.length == 0 && (
                <span className="mr-2">Add store on source config</span>
              )}
              <button className="btn btn-primary" onClick={handleAddItem}>
                +
              </button>
            </div>

            {/* <button onClick={handleAddItem}>Add Item</button> */}
          </div>
          <div className="row mt-4">
            <strong className="mb-2">Attributes</strong> 
            {console.log("attributes :" , JSON.stringify(attributes))}
            {console.log("switchstates :" , switchStates)}
            

            {attributes &&
              attributes.map((item, index) => (
                
                
                <div class="form-group col-md-12 row block-shadow">
                <div className="col-md-12">
                <Switch
                checkedChildren="Dynamic"
                unCheckedChildren="Static"
                checked={switchStates[index]}
                onChange={(checked) => handleSwitchChange(index, "attribute_type", checked)}
              />
                </div>
                  {!switchStates[index]? 
                  <>
                  <PEDiv element_id="transformationmapp_attribultkey" class="form-group col-md-5">
                    <label>Attribute Key</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Attribute Key Name"
                      value={item.attribute_key}
                      onChange={(e) =>
                        handleInputChangeAttribute(
                          index,
                          "attribute_key",
                          e.target.value
                        )
                      }
                    />
                  </PEDiv>
                  <PEDiv element_id="transformationmapp_attributevalue" class="form-group col-md-5">
                    <label>Attribute Value</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Attribute Value"
                      value={item.attribute_value}
                      onChange={(e) =>
                        handleInputChangeAttribute(
                          index,
                          "attribute_value",
                          e.target.value
                        )
                      }
                    />
                  </PEDiv>
                  </>
                  :
                  <>
                  <div class="form-group col-md-5">
                    <label>Attribute Key</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Attribute Key Name"
                      value={item.attribute_key}
                      onChange={(e) =>
                        handleInputChangeAttribute(index, "attribute_key", e.target.value)
                      }
                    />
                  </div>
                  <div class="form-group col-md-5">
                    <label>Attribute Value</label>
                    <select
                      className="form-control"
                      name="field_name"
                      value={item.attribute_value}
                      onChange={(e) =>
                        handleInputChangeAttribute(index, "attribute_value", e.target.value)
                      }
                    >
                      <option value="">Select Attribute Value </option>
                      {eventD && eventD == "O"
                        ? vendorfield_list &&
                          vendorfield_list.map((vendorfield) => (
                            <option value={vendorfield.payload_field}>
                              {vendorfield.payload_field}
                            </option>
                          ))
                        : eventD == "I"
                        ? ndfield_list &&
                          ndfield_list.map((ndfield) => (
                            <option value={ndfield.payload_field}>
                              {ndfield.payload_field}
                            </option>
                          ))
                        : ""}
                    </select>
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Attribute Key Name"
                      value={item.attribute_key}
                      onChange={(e) =>
                        handleInputChangeAttribute(index, "attribute_key", e.target.value)
                      }
                    /> */}
                  </div>
                  
                  </>}
                 
                  <div className="form-group col-md-1">
                    {attributes.length !== 1 && (
                      <>
                        <button
                          className="btn btn-danger form-group mr-2"
                          onClick={() => handleRemoveItemAttribute(index)}
                        >
                          -
                        </button>
                      </>
                    )}
                    {attributes.length - 1 === index && (
                      <>
                        <PEButton element_id="transformationmapp_addattribute"
                          className="btn btn-primary form-group"
                          onClick={handleAddItemAttribute}
                        >
                          +
                        </PEButton>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="row mt-4">
            <strong className="mb-2">Condition</strong>
            <hr />
            {conditionfieldlist &&
              conditionfieldlist.map((condition, index) => (
                <>
                  <div className="form-group col-md-12 row block-shadow">
                    <PEDiv  element_id="transformationmapp_field" className="form-group col-md-3">
                      <strong>Fields</strong>
                      <p style={{ "word-wrap": "break-word" }}>
                        {condition.field_name}
                      </p>
                    </PEDiv>
                    <PEDiv element_id="transformationmapp_fieldvalue" className="form-group col-md-3">
                      <strong>Fields value</strong>
                      <p style={{ "word-wrap": "break-word" }}>
                        {condition.field_value}
                      </p>
                    </PEDiv>
                    <PEDiv element_id="transformationmapp_select operator" className="form-group col-md-3">
                      <strong>Select Operator</strong>
                      <p style={{ "word-wrap": "break-word" }}>
                        {condition.operator}
                      </p>
                    </PEDiv>
                    <PEDiv element_id="transformationmapp_condition" className="form-group col-md-3">
                      <strong>Select Condition Type</strong>
                      <p style={{ "word-wrap": "break-word" }}>
                        {condition.condition_type}
                      </p>
                    </PEDiv>
                  </div>
                </>
              ))}
          </div>
          <div className="mt-4">
            <strong className="mb-2 col-md-12">is Aggregate?</strong>
            <PEDiv element_id="transformationmapp_aggregate" className="form-row">
              <select
                className="form-control col-md-4"
                name="aggregate_flag"
                value={aggregate_flag}
                onChange={(e) => setaggregate_flag(e.target.value)}
              >
                <option value="">select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              {aggregate_flag == 1 && (
                <>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      name="group_id_key_name"
                      value={group_id_key_name}
                      onChange={(e) => setgroup_id_key_name(e.target.value)}
                    >
                      <option value="">Select Field</option>
                      {eventD && eventD == "I"
                        ? vendorfield_list &&
                          vendorfield_list.map((vendorfield) => (
                            <option value={vendorfield.payload_field}>
                              {vendorfield.payload_field}
                            </option>
                          ))
                        : eventD == "O"
                        ? ndfield_list &&
                          ndfield_list.map((ndfield) => (
                            <option value={ndfield.payload_field}>
                              {ndfield.payload_field}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <input
                      placeholder="Group id key field name"
                      className="form-control"
                      name="group_id_key_field_name"
                      value={group_id_key_field_name}
                      onChange={(e) =>
                        setgroup_id_key_field_name(e.target.value)
                      }
                    />
                  </div>
                </>
              )}
            </PEDiv>
          </div>
          {/* <div className="row mt-4">
            <strong className="mb-2">Add Mapping</strong>
            <hr />
            {fieldlist_show &&
              fieldlist_show.map((fields, index) => (
                <>
                  <div className="form-group col-md-12 row block-shadow">
                    <div className="form-group col-md-3">
                      <label>ND Fields</label>
                      <select
                        className="form-control"
                        name="NDFields"
                        value={fields.NDFields}
                        onChange={(e) => handlevendorInputChange(e, index)}
                      >
                        <option value="">Select Field</option>
                        {ndfield_list &&
                          ndfield_list.map((ndfield) => (
                            <option value={ndfield.payload_field}>
                              {ndfield.payload_field}
                            </option>
                          ))}
                      </select>
                      <span className="break-words">{fields.NDFields}</span>
                    </div>
                    <div className="form-group col-7">
                      <label>Vendor Fields</label>
                      <select
                        className="form-control"
                        name="VendorFields"
                        value={fields.VendorFields}
                        onChange={(e) => handlevendorInputChange(e, index)}
                      >
                        <option value="">Select Field</option>
                        {vendorfield_list &&
                          vendorfield_list.map((vendorfield) => (
                            <option value={vendorfield.payload_field}>
                              {vendorfield.payload_field}
                            </option>
                          ))}
                      </select>
                      <span className="break-words">{fields.VendorFields}</span>
                    </div>
                    <div className="form-group col-md-2">
                      <label>Vendor Default Value</label>
                      <input
                        className="form-control"
                        name="ven_defaultval"
                        value={fields.ven_defaultval}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                    <div className="form-group col-md-2">
                      <label>Value Transformation ?</label>
                      <select
                        className="form-control"
                        name="isTransform"
                        value={fields.isTransform}
                        onChange={(e) => handleInputChange(e, index)}
                      >
                        <option value="">Select Field</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    
                    <div className="form-group col-md-6">
                      <div>
                        <label>Child Mapping</label>
                        {fields.child_mappings &&
                          fields.child_mappings.map((child, childIndex) => (
                            <div key={childIndex} className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                value={child.key}
                                onChange={(e) =>
                                  handleTextboxChange(
                                    index,
                                    childIndex,
                                    "key",
                                    e.target.value
                                  )
                                }
                              />
                              <input
                                type="text"
                                className="form-control"
                                value={child.value}
                                onChange={(e) =>
                                  handleTextboxChange(
                                    index,
                                    childIndex,
                                    "value",
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  handleRemoveChildMapping(index, childIndex)
                                }
                              >
                                -
                              </button>
                            </div>
                          ))}
                        <div className="col-md-12">
                          <button
                            className="btn btn-primary"
                            onClick={() => handleAddChildMapping(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-2">
                      {fieldlist.length !== 1 && (
                        <>
                          <button
                            className="btn btn-danger form-group mr-2"
                            onClick={() => handleRemoveClick(index)}
                          >
                            -
                          </button>
                        </>
                      )}
                      {fieldlist.length - 1 === index && (
                        <>
                          <button
                            className="btn btn-primary form-group"
                            onClick={handleAddfield}
                          >
                            +
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </div>
          {fieldlist_show.length != fieldlist.length && (
            <div className="text-center mt-3">
              <button
                className="btn btn-primary"
                onClick={() => loadMoreData()}
              >
                Load More
              </button>
            </div>
          )} */}
          <div className="text-center mt-2">
            <PEButton element_id="transformationmapp_save"
              className="btn btn-primary"
              disabled={isDisabled}
              onClick={save_mapping}
            >
              save
            </PEButton>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Transformation_mapping;
