import React, { useState } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";

function DecryptPayload(props) {
  const [request, sendRequest] = useAxios();

  const [loading, setLoading] = useState(false);
  const [isErrOpen, setIsErrOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [encryptedPayload, setEncryptedPayload] = useState("");
  const [securityToken, setSecurityToken] = useState("");
  const [outputData, setOutputData] = useState("");

  const decryptPayload = async () => {
    if (!encryptedPayload.trim() || !securityToken.trim()) {
      setIsErrOpen(true);
      setErrorMessage(
        "Please enter both Encrypted Payload and Security Token."
      );
      console.log("outputData", outputData);

      return;
    }
    let apitoken = localStorage.getItem("api_token");

    setLoading(true);
    try {
      const formData = {
        rsa_encrypted_aes_key: securityToken,
        aes_encrypted_payload_data: encryptedPayload,
      };

      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/decrypt`,
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },

        (response) => {
          console.log("response", response);
          if (response && response.decrypted_data) {
            setOutputData(response.decrypted_data);
          } else {
            throw new Error("Decryption failed or no data returned.");
          }
        }
      );
    } catch (err) {
      setIsErrOpen(true);
      setErrorMessage(`Decryption or API call failed: ${err.message}`);
      setOutputData("");
      console.log("outputData", outputData);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Commonheader pagetitle={"Decrypt Payload"} props={props} />

      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}

      {isErrOpen && (
        <div
          className="modal fade show d-block mymodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="errorModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                onClick={() => setIsErrOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="popuperrtext">{errorMessage}</p>
            </div>
          </div>
        </div>
      )}

      <div className="mb-3">
        <div className="form-group">
          <label htmlFor="encryptedPayload">Encrypted Payload</label>
          <textarea
            id="encryptedPayload"
            value={encryptedPayload}
            rows="4"
            onChange={(e) => setEncryptedPayload(e.target.value)}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "20px",
            }}
            placeholder="Enter Encrypted Payload"
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="securityToken">Security Token</label>
          <textarea
            id="securityToken"
            value={securityToken}
            rows="2"
            onChange={(e) => setSecurityToken(e.target.value)}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "20px",
            }}
            placeholder="Enter Security Token"
          ></textarea>
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-primary"
            onClick={decryptPayload}
            disabled={loading}
          >
            {loading ? "Decrypting..." : "Decrypt"}
          </button>
        </div>
      </div>

      {outputData && (
        <div className="output-section" style={{ marginTop: "10px" }}>
          <h3>Decrypted Text</h3>
          <pre
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontFamily: "monospace",
              whiteSpace: "pre-wrap", 
              wordWrap: "break-word",
            }}
          >
            {outputData}
          </pre>
        </div>
      )}
    </div>
  );
}

export default DecryptPayload;
